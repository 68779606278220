import React from 'react';
import { withPublicLink, AuthorisationConsumer } from '@devsta/common-react';
import CoreLayoutComponent from '../components/CoreLayout';

function CoreLayout(props) {
  return (
    <AuthorisationConsumer>
      {({ selectedOrganisation: { imageUrl, name } }) => (
        <CoreLayoutComponent logoUrl={imageUrl} organisationName={name} {...props} />
      )}
    </AuthorisationConsumer>
  );
}
export default withPublicLink(CoreLayout);
