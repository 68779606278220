// @flow
import React, { memo } from 'react';
import { Text, Image, Dropdown } from '@devsta/ui-kit';
import { requiresPermission, hasPermission } from '@devsta/common-react';
import type { RouterHistory as History } from 'react-router-dom';
import UserAvailability from './UserAvailability';
import OutputsList from './OutputsList';
import '../styles/_user-summary.scss';

const OutputsListComponent = requiresPermission({
  ComponentToRender: OutputsList,
  requiredPermission: 'INTERNAL'
});

type UserSummaryProps = {
  userId: string,
  imageUrl?: string,
  isGhosting: boolean,
  handleSignOut: Function,
  handleStopGhosting: Function,
  toggleAdminMode: Function,
  adminMode: boolean,
  showSwitchOrganisationModal: Function,
  selectedOrganisation: Object,
  organisationMemberships: Array<Object>,
  history: History,
  isAdminMode: boolean
};

function goToUrl(history) {
  return ({ value }) => {
    history.push(value);
  };
}

function UserSummary(props: UserSummaryProps) {
  const {
    userId,
    isGhosting,
    handleSignOut,
    handleStopGhosting,
    showSwitchOrganisationModal,
    selectedOrganisation,
    organisationMemberships,
    isAdminMode,
    history,
    adminMode,
    toggleAdminMode
  } = props;

  const {
    imageUrl: orgImageUrl,
    name: orgName,
    permissions,
    organisationId
  } = selectedOrganisation;

  const currentMembership = organisationMemberships.find((
    ({ organisation }: Object) => organisationId === organisation.organisationId)
  );

  const hasAnotherWorkspace = organisationMemberships.length > 1;
  const userEditLink = `/user-settings/edit/${userId}`;
  const hasOrgPermission = hasPermission('ORGANISATION', permissions);
  const pushUrl = goToUrl(history);

  return (
    <div className="user-summary">
      <div className="user-summary__container">
        <Dropdown.Label prefixSize={20} data={{ label: 'Manage Profile', prefix: 'user', value: userEditLink }} selectOption={pushUrl} />
        {isGhosting && (
          <Dropdown.Label prefixSize={20} data={{ label: 'Stop Ghosting', prefix: 'ghost', value: userEditLink }} selectOption={handleStopGhosting} />
        )}
        <hr />
        <Dropdown.Label
          prefixSize={20}
          disabled={!hasOrgPermission}
          data={{
            label: (
              <div className="user-summary__organisation-info">
                <Image
                  width="20px"
                  height="20px"
                  imageUrl={orgImageUrl}
                  backgroundSize="cover"
                  placeholderText={orgName}
                  rounded
                  placeholderTextSize={10}
                />
                <Text noMargin className="user-summary__organisation-info__description__name">
                  {orgName}
                </Text>
              </div>
            ),
            value: '/organisation/'
          }}
          selectOption={pushUrl}
        />
        {
          toggleAdminMode && (
            <Dropdown.Label
              prefixSize={20}
              data={{
                label: adminMode ? 'Exit Administration' : 'Administration',
                prefix: 'intaccess'
              }}
              selectOption={toggleAdminMode}
            />
          )
        }
        {
          currentMembership && currentMembership.role.name === 'SHOOTSTA_EDITOR' ? (
            <>
              <UserAvailability selectedOrganisation={selectedOrganisation} />
              <OutputsListComponent permissions={permissions} />
            </>
          ) : null
        }
        {!isAdminMode && hasAnotherWorkspace && (
          <>
            <hr />
            <Dropdown.Label prefixSize={20} data={{ label: 'Sign in to another workspace', prefix: 'organisation' }} selectOption={showSwitchOrganisationModal} />
          </>
        )}
        <hr />
        <Dropdown.Label prefixSize={20} data={{ label: 'Sign Out', prefix: 'exit' }} selectOption={handleSignOut} />
      </div>
    </div>
  );
}

export default memo<UserSummaryProps>(UserSummary);
