// @flow

import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import type { RouterHistory } from 'react-router-dom';
import { ReactComponent as Wave } from '../../../../assets/images/wave.svg';
import { ReactComponent as Person } from '../../../../assets/images/person.svg';
import { ReactComponent as Auth } from '../../../../assets/images/forgot.svg';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';
import '../styles/_login.scss';

type Props = {
  loginRequest: Function,
  forgotPassword: Function,
  history: RouterHistory
};

const Login = ({ loginRequest, forgotPassword, history }: Props) => (
  <div className="login">
    <div className="login__container" data-cy="login-container">
      <div className="login__left-content">
        <Switch>
          <Route
            path="/login/forgot"
            render={() => <ForgotPassword history={history} forgotPassword={forgotPassword} />}
          />
          <Route render={() => <LoginForm history={history} loginRequest={loginRequest} />} />
        </Switch>
      </div>
      <MediaQuery minWidth={640}>
        <div className="login__side-panel">
          <Wave />
          <Wave />
          <div className="login__side-panel__inner">
            {history.location.pathname.includes('forgot') ? <Auth /> : <Person />}
          </div>
        </div>
      </MediaQuery>
    </div>
  </div>
);

export default memo<Props>(Login);
