import React, { Component } from 'react';
import memoize from 'memoize-one';
import cx from 'classnames';
import type { Node } from 'react';
import MediaQuery from 'react-responsive';
import type { Location, RouterHistory } from 'react-router-dom';
import { NavigationMenu, NavigationMenuMobile } from '@devsta/ui-kit';
import { hasPermission } from '@devsta/common-react';
import AdminModeContext from '../../../common/contexts/AdminMode';
import { topNavLinks, bottomNavLinks, topAdminNavLinks, MEDIA_SIZES } from '../../../common/constants';
import GlobalHeader from '../../../common/components/GlobalHeader';
import '../styles/_core-layout.scss';

type CoreLayoutProps = {
  children: Node,
  location: Location,
  history: RouterHistory,
  orgPermissions: Array<string>,
  logoUrl?: string,
  organisationName: string
};

type CoreLayoutState = {
  adminMode: boolean,
  appSwitcherVisible: boolean
}

const INTERNAL_URL_SEGMENTS = ['internal', 'traffic'];

function getFirstUrlSegment(url) {
  return `/${url.split('/')[1]}`;
}

export default class CoreLayout extends Component<CoreLayoutProps, CoreLayoutState> {
  constructor(props: CoreLayoutProps) {
    super(props);

    const { location, history } = props;

    this.state = {
      appSwitcherVisible: false,
      adminMode: this.isInternalUrl(location.pathname)
    };

    history.listen(({ pathname }) => {
      if (pathname !== '/') {
        this.setState({ adminMode: this.isInternalUrl(pathname) });
      }
    });
  }

  getRoutes = memoize(({ pathname }, links) => {
    const { orgPermissions } = this.props;

    return (
      links
        .filter(({ requiredPermission }) => (
          !requiredPermission || hasPermission(requiredPermission, orgPermissions)
        ))
        .map((route) => {
          const matchedRoute = route.url === pathname || route.url === getFirstUrlSegment(pathname);

          return matchedRoute ? { ...route, selected: true } : route;
        })
    );
  }
  );

  isInternalUrl = (pathname: string) => {
    const firstUrlSegment = getFirstUrlSegment(pathname);

    return INTERNAL_URL_SEGMENTS.some(urlSegment => firstUrlSegment === `/${urlSegment}`);
  };

  toggleAdminMode = () => {
    const { history } = this.props;
    const { adminMode: isAdminMode } = this.state;

    const path = isAdminMode ? '/video-tool' : '/';

    this.setState(({ adminMode }) => ({ adminMode: !adminMode }), () => {
      history.push(path);
    });
  };

  showSwitchOrganisationModal = () => {
    this.setState({ appSwitcherVisible: true });
  };

  hideSwitchOrganisationModal = () => {
    this.setState({ appSwitcherVisible: false });
  };

  createProject = () => {
    const { history } = this.props;

    history.push('/video-tool/builder/new');
  };

  render() {
    const {
      children,
      location,
      history,
      logoUrl,
      orgPermissions,
      organisationName
    }: CoreLayoutProps = this.props;
    const { adminMode, appSwitcherVisible } = this.state;
    const topLinks = adminMode ? topAdminNavLinks : topNavLinks;
    const topRoutes = this.getRoutes(location, topLinks, adminMode);
    const bottomRoutes = this.getRoutes(location, bottomNavLinks, adminMode);
    const createProjectButtonProps = { children: 'Create Project', onClick: this.createProject };

    return (
      <div className={cx('core-layout', { 'core-layout__no-scroll': appSwitcherVisible })}>
        <MediaQuery minWidth={MEDIA_SIZES.small + 1}>
          <NavigationMenu
            history={history}
            topRoutes={topRoutes}
            bottomRoutes={bottomRoutes}
            primaryColour={adminMode ? 'monkey' : void 0}
            primaryLinksColour={adminMode ? 'white' : void 0}
            primaryLinksHoverColour={adminMode ? 'aqua' : void 0}
            primaryLinksSelectedColour={adminMode ? 'bermudaBlue' : void 0}
            isExpandable
            logoUrl={adminMode ? void 0 : logoUrl}
            title={organisationName}
            primaryButtonProps={createProjectButtonProps}
          />
        </MediaQuery>
        <div className="core-layout__content">
          <AdminModeContext.Provider value={adminMode}>
            <MediaQuery minWidth={MEDIA_SIZES.small + 1}>
              <GlobalHeader
                showSwitchOrganisationModal={this.showSwitchOrganisationModal}
                appSwitcherVisible={appSwitcherVisible}
                onCloseSwitchOrganisationModal={this.hideSwitchOrganisationModal}
                toggleAdminMode={hasPermission('INTERNAL', orgPermissions) && this.toggleAdminMode}
                adminMode={adminMode}
              />
            </MediaQuery>
            <MediaQuery maxWidth={MEDIA_SIZES.small}>
              <NavigationMenuMobile
                history={history}
                topRoutes={topRoutes}
                bottomRoutes={bottomRoutes}
                primaryColour={adminMode ? 'monkey' : void 0}
                primaryLinksColour={adminMode ? 'white' : void 0}
                primaryLinksSelectedColour={adminMode ? 'bermudaBlue' : void 0}
                isExpandable
                logoUrl={logoUrl}
                toggleAdminMode={hasPermission('INTERNAL', orgPermissions) && this.toggleAdminMode}
                adminMode={adminMode}
                primaryButtonProps={createProjectButtonProps}
              />
            </MediaQuery>
            {children}
          </AdminModeContext.Provider>
        </div>
      </div>
    );
  }
}
