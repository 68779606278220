// @flow

import React, { memo } from 'react';
import { Icon, Text } from '@devsta/ui-kit';
import '../styles/_login-header.scss';

type LoginHeaderProps = {
  title: string,
  subtitle: string,
  hideIcon?: boolean
}

function LoginHeader({ title, subtitle, hideIcon }: LoginHeaderProps) {
  return (
    <div className="login-header">
      { !hideIcon && <Icon name="shootstacolour" size={50} /> }
      <Text heading2 noMargin>{title}</Text>
      <Text heading4 noMargi>{subtitle}</Text>
    </div>
  );
}

export default memo<LoginHeaderProps>(LoginHeader);
