import React from 'react';
import { Input, Button, Link, Icon } from '@devsta/ui-kit';
import '../styles/_login-modal.scss';

type LoginFormProps = {
  onCancel: Function,
  handleLogin: Function,
  updateEmail: Function,
  updatePassword: Function,
  busy: boolean,
  error?: string
};

export default function LoginForm({
  onCancel,
  handleLogin,
  updateEmail,
  updatePassword,
  busy,
  error
}: LoginFormProps) {
  return (
    <>
      <p className="login-form__title">Welcome Back!</p>
      <div className="login-form__body">
        <Input label="Email" labelProps={{ required: true }} className="login-form__input" onChange={updateEmail} placeholder="Enter email" />
        <Input label="Password" labelProps={{ required: true }} type="password" className="login-form__input" onChange={updatePassword} placeholder="Enter password" />
        <hr className="login-form__horizontal-line" />
        <p className="login-form__text">
          Jump back in to the HUB and start reviewing. Invite others to collaborate
          and join you on your video production journey.
        </p>
        <Button className="login-form__button" onClick={handleLogin} busy={busy}>
          Login
        </Button>
        <Link className="login-form__cancel-button" onClick={onCancel}>
          Cancel
        </Link>
      </div>
      {error
        ? (
          <div className="login-form__body__error">
            <Icon name="alert" color="thunderBird" />
            <p>{error}</p>
          </div>
        ) : null
      }
    </>
  );
}
