import { hasPermission } from '@devsta/common-react';

const getHomePage = (orgPermissions: string[] = [], orgPreferences: { homepage?: string }) => {
  if (hasPermission('INTERNAL', orgPermissions)) {
    return '/traffic';
  }
  if (orgPreferences && orgPreferences.homepage) {
    return orgPreferences.homepage;
  }
  return '/video-tool';
};

export default getHomePage;
