import React from 'react';
import { Icon, Input, Button, Link } from '@devsta/ui-kit';
import '../styles/_welcome-guest.scss';

type AnonymousUserFormProps = {
  displayLoginForm: Function,
  handleAnonymousUser: Function,
  updateFirstName: Function,
  updateLastName: Function,
  busy: boolean,
  disabled: boolean,
  error?: string
};

const HELP_URL = 'https://intercom.help/shootstahub/en';


function openHelpPage() {
  window.open(HELP_URL, '_blank');
}

export default function AnonymousUserForm({
  displayLoginForm,
  handleAnonymousUser,
  updateFirstName,
  updateLastName,
  busy,
  error,
  disabled
}: AnonymousUserFormProps) {
  return (
    <>
      <div className="welcome-guest__header">
        <Icon name="projects" size={15} color="white" />
        <div className="welcome-guest__title">
          <p className="welcome-guest__primary-title">Hello there!</p>
          <p className="welcome-guest__secondary-title">
            Please enter your
            <b>&nbsp;full name&nbsp;</b>
            or if you have an account, log back into the HUB
          </p>
        </div>
      </div>
      <div className="welcome-guest__body">
        <div className="welcome-guest__body__left-content">
          <Input label="First Name" className="welcome-guest__body__input" onChange={updateFirstName} />
          <Input label="Last Name" className="welcome-guest__body__input" onChange={updateLastName} />
          <Button disabled={disabled} className="welcome-guest__body__button" onClick={handleAnonymousUser} busy={busy}>
            Start Reviewing
          </Button>
          <p className="welcome-guest__body__text">Or</p>
          <Button type="SECONDARY" className="welcome-guest__body__button" onClick={displayLoginForm}>
            Log Back In
          </Button>
        </div>
        <div className="welcome-guest__body__right-content">
          <b className="welcome-guest__body__right-content__primary-text">
            A brighter, more accessible content library
          </b>
          <p className="welcome-guest__body__right-content__secondary-text">
            We continue to disrupt the video production industry by combining tools, technology,
            education and expertise to simplify the video production process.
            We enable people everywhere to make quality video content at scale,
            ready to share in just 24 hours.
          </p>
          <p className="welcome-guest__body__right-content__secondary-text">
            We call it the perfect blend between in-house and outsourced video production.
            That’s Video Simplified.
          </p>
        </div>
      </div>
      <div className="welcome-guest__footer">
        <Link
          className="welcome-guest__footer__link"
          onClick={openHelpPage}
        >
          Need Help?
        </Link>
      </div>
      {error
        ? (
          <div className="welcome-guest__body__error">
            <Icon name="alert" color="thunderBird" />
            <p>{error}</p>
          </div>
        ) : null
      }
    </>
  );
}
