// @flow
import { memo } from 'react';
import { credentials } from '@devsta/client-auth';

type BookingsLinkProps = {
  selectedOrganisation: Object
};

function BookingsLink({
  selectedOrganisation
}: BookingsLinkProps) {
  const payload = btoa(JSON.stringify({
    organisation: selectedOrganisation,
    ...credentials.get() }));
  window.location.href = `http://bookings${process.env.NODE_ENV !== 'production' ? '-test' : ''}.shootsta.com/sso/hub?version=3.0&token=${payload}`;
  return null;
}

export default memo<BookingsLinkProps>(BookingsLink);
