import REQUIRED_PERMISSIONS from './requiredPermissions';

const {
  SCRIPTS,
  STORYBOARDS,
  DAM,
  ORGANISATION,
  VIDEO_TOOL,
  INSPIRATIONS,
  BOOKINGS
} = REQUIRED_PERMISSIONS;

export const topNavLinks = [
  {
    label: 'Projects',
    url: '/video-tool',
    icon: 'video',
    requiredPermission: VIDEO_TOOL
  },
  { label: 'Scripts', url: '/scripts', icon: 'script', requiredPermission: SCRIPTS },
  {
    label: 'Storyboards',
    url: '/storyboards',
    icon: 'storyboard',
    requiredPermission: STORYBOARDS
  },
  {
    label: 'Media Library',
    url: '/medialibrary',
    icon: 'medialibrary',
    requiredPermission: DAM
  },
  {
    label: 'Inspirations',
    url: '/inspiration',
    icon: 'inspiration',
    requiredPermission: INSPIRATIONS
  },
  {
    label: 'Bookings',
    url: '/bookings',
    icon: 'bookings',
    requiredPermission: BOOKINGS,
    onClick: () => window.open('/bookings', '_blank')
  }
];

export const bottomNavLinks = [
  {
    label: 'Organisation',
    url: '/organisation',
    icon: 'company',
    requiredPermission: ORGANISATION
  },
  {
    label: 'Live Support',
    icon: 'livechat',

    // Intercom will inject the action, so we just pass a noop
    onClick: Function.prototype
  },
  {
    label: 'Help Center',
    onClick: () => window.open('https://intercom.help/shootstahub/en', '_blank'),
    icon: 'faq'
  }
];
