import { gql } from 'apollo-boost';

const GET_OUTPUTS_QUERY = gql`
    query GetTrafficOutputs($filter: TrafficOutputFilterInput, $limit: Int, $offset: Int) {
        getTrafficOutputs(filter: $filter, limit: $limit, offset: $offset) {
            count
            outputs {
                outputId
                status {
                    name
                }
                video {
                    name
                    organisation{
                        organisationId
                        imageUrl
                    }
                }
                versions {
                    versionNumber
                    dueDate
                    appliedAddons {
                        RUSH_EDIT {
                            value
                        }
                    }
                }
            }
        }
    }
`;

export default (
  { filter, limit }: { filter: Object, limit: number }
) => ({
  query: GET_OUTPUTS_QUERY,
  module: 'video',
  ignoreCache: true,
  buildVariables: () => ({ filter, limit })
});
