// @flow
import React, { Component } from 'react';
import { credentials } from '@devsta/client-auth';
import { replaceBucketKeysWithUrls } from '@devsta/client-aws-s3';
import type { RouterHistory as History } from 'react-router-dom';
import type { User } from '@devsta/common-flow-types';
import NotificationsSummary from './NotificationsSummary';
import UserWrapper from './UserWrapper';
import CreditsWrapper from './CreditsWrapper';
import SwitchOrganisationModal from './SwitchOrganisationModal';
import { redirectToSingleLogout, getSSOLocalStore, clearSSOLocalStore } from '../../../../utils';
import '../styles/_global-header.scss';

type GlobalHeaderProps = {
  showSwitchOrganisationModal: Function,
  appSwitcherVisible: boolean,
  onCloseSwitchOrganisationModal: Function,
  apolloClient: Object,
  selectedOrganisation: Object,
  notifications: Object,
  history: History,
  adminMode: boolean,
  toggleAdminMode: Function
};

type GlobalHeaderState = {
  userId: string,
  fullName: string,
  imageUrl: string,
  email: string,
  isGhosting: boolean,
  original: Object,
  origURL: string
};

export default class GlobalHeader extends Component<GlobalHeaderProps, GlobalHeaderState> {
  constructor(props: GlobalHeaderProps) {
    super(props);

    const { userId, firstName, lastName, imageUrl, email } = credentials.get().user;
    const { original = null, origURL = '' } = credentials.get();
    credentials.listenToChange(this.handleCredentialsChange);

    const fullName = `${firstName} ${lastName}`;

    this.state = {
      userId,
      fullName,
      imageUrl,
      email,
      isGhosting: Boolean(original),
      original,
      origURL
    };
  }

  componentDidMount() {
    this.getPreSignedImageUrl();
  }

  getPreSignedImageUrl = async () => {
    const { selectedOrganisation: { organisationId }, apolloClient } = this.props;
    const { imageUrl } = this.state;

    try {
      const { imageUrl: preSignedImageUrl } = await replaceBucketKeysWithUrls({
        apiClient: apolloClient,
        data: { imageUrl },
        substitutedKeys: ['imageUrl'],
        organisationId
      });

      this.setState({ imageUrl: preSignedImageUrl });
    } catch {
      // Silently handle error
    }
  };

  handleCredentialsChange = (userCredentials?: { user: ?User }) => {
    if (!userCredentials || !userCredentials.user) {
      return;
    }
    const { user } = userCredentials;
    this.setState({
      ...user,
      fullName: `${user.firstName} ${user.lastName}`,
      // If there's an image, replace blob:// form image with the server-side
      // image (otherwise it flashes and disappears), then the presigned one
      // and if there's no image, show the initials
      imageUrl: user.imageUrl
        ? `user/${user.userId}/profileimage`
        : user.imageUrl
    }, this.getPreSignedImageUrl);
  }


  handleSignOut = () => {
    const { ssoEnabled } = getSSOLocalStore();
    const { isGhosting } = this.state;

    clearSSOLocalStore();

    // If ghosting or not ssoEnabled, show HUB login page
    if (!ssoEnabled || isGhosting) {
      return void credentials.clear();
    }

    const { history } = this.props;

    history.push({ search: '?redirecting=true' });
    credentials.clear();

    return void redirectToSingleLogout();
  };

  handleStopGhosting = () => {
    const {
      original: {
        token,
        user,
        original = null
      },
      origURL: targetURL
    } = this.state;

    const { history } = this.props;

    clearSSOLocalStore();

    history.push(
      `/?ghost=${btoa(JSON.stringify({ clearGhost: true, token, user, original, targetURL }))}`
    );
  };

  render() {
    const {
      userId,
      fullName,
      imageUrl,
      email,
      isGhosting
    } = this.state;
    const {
      showSwitchOrganisationModal,
      selectedOrganisation,
      appSwitcherVisible,
      onCloseSwitchOrganisationModal,
      notifications,
      adminMode,
      toggleAdminMode
    } = this.props;

    return (
      <header>
        <div className="column global-header-container">
          <CreditsWrapper />
          <NotificationsSummary {...notifications} />
          <UserWrapper
            userId={userId}
            fullName={fullName}
            imageUrl={imageUrl}
            isGhosting={isGhosting}
            email={email}
            handleSignOut={this.handleSignOut}
            handleStopGhosting={this.handleStopGhosting}
            showSwitchOrganisationModal={showSwitchOrganisationModal}
            selectedOrganisation={selectedOrganisation}
            adminMode={adminMode}
            toggleAdminMode={toggleAdminMode}
          />
          <SwitchOrganisationModal
            visible={appSwitcherVisible}
            onClose={onCloseSwitchOrganisationModal}
          />
        </div>
      </header>
    );
  }
}
