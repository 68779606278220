// @flow
import React from 'react';
import { Text } from '@devsta/ui-kit';
import { notificationTypeStrings } from '../../../../constants';

type Props = {
  notificationId: string,
  fullName: string,
  link: string,
  read: boolean,
  type: string,
  displayedCreatedAt: string,
  className: string,
  onLinkSelect: Function
};

export default function NotificationItem(props: Props) {
  const {
    notificationId,
    fullName,
    link,
    read,
    type,
    displayedCreatedAt,
    className,
    onLinkSelect
  } = props;

  return (
    <a
      className={`${className}__item`}
      target="_blank"
      href={link}
      rel="noopener noreferrer"
      onClick={onLinkSelect(notificationId)}
    >
      <div className={`${className}__item__content`}>
        <Text noMargin highlighted={!read}>{`${fullName} ${notificationTypeStrings[type]}`}</Text>
        <Text noMargin timestamp>{displayedCreatedAt}</Text>
      </div>
      {!read && <span className={`${className}__item__unread`} />}
    </a>
  );
}
