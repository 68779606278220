const { INTERNAL } = require('./requiredPermissions').default;

export const topNavLinks = [
  {
    label: 'Manage Clients',
    url: '/internal/organisations',
    icon: 'web',
    requiredPermission: INTERNAL
  },
  {
    label: 'My Dashboard',
    url: '/traffic',
    icon: 'dashboard',
    requiredPermission: INTERNAL
  },
  {
    label: 'Reporting',
    url: '/internal/reporting',
    icon: 'mediareport',
    requiredPermission: INTERNAL
  }
];
