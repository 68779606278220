// @flow

import React from 'react';
import { CreditsInfo } from '@devsta/common-react';
import './styles/_credits-wrapper.scss';

function CreditsWrapper() {
  return (
    <div className="global-credits-wrapper">
      <CreditsInfo />
    </div>
  );
}

export default CreditsWrapper;
